import PromoQuiz from '@promo-platform/promo-quiz';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  handleIframeMessage,
  onSuccessCallback,
  postMessageToParent,
} from 'utils/iframeUtils';

const Quizzes = () => {
  const { quizId } = useParams<{ quizId?: string }>();
  const ref = useRef(null);
  const observer = useRef(
    new ResizeObserver(() => {
      postMessageToParent();
    })
  );

  useEffect(() => {
    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, observer]);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return quizId ? (
    <div ref={ref}>
      <PromoQuiz
        url={process.env.REACT_APP_EXTERNAL_QUIZESS_URL as string}
        id={quizId}
        onSuccessCallback={onSuccessCallback}
      />
    </div>
  ) : null;
};

export default Quizzes;
