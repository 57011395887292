const POST_MESSAGE_DELAY = 100;

export const postMessageToParent = () => {
  setTimeout(() => {
    window.parent.postMessage({ height: document.body.scrollHeight }, '*');
  }, POST_MESSAGE_DELAY);
};

export const handleIframeMessage = (event: MessageEvent) => {
  if (event.data !== 'height') {
    return;
  }

  if (!(event.source instanceof MessagePort)) {
    event.source?.postMessage(
      { height: document.body.scrollHeight },
      '*' as any
    );
  }
};

export const onSuccessCallback = (url: string) => {
  if (window.parent) {
    window.parent.postMessage({ redirectUrl: url }, '*');
  }
};
