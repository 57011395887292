import React from 'react';
import { useSearchParams } from 'react-router-dom';

const Vanish = React.lazy(() => import('../../themes/Vanish'));
const Dettol = React.lazy(() => import('../../themes/Dettol'));
const Finish = React.lazy(() => import('../../themes/Finish'));
const Default = React.lazy(() => import('../../themes/Default'));
const FinishUS = React.lazy(() => import('../../themes/FinishUS'));
const DurexES = React.lazy(() => import('../../themes/DurexES'));
const FinishUK = React.lazy(() => import('../../themes/FinishUK'));
const Airwick = React.lazy(() => import('../../themes/Airwick'));
const AirwickUK = React.lazy(() => import('../../themes/AirwickUK'));
const AirwickUKSubscribe = React.lazy(
  () => import('../../themes/AirwickUKSubscribe')
);
const Harpic = React.lazy(() => import('../../themes/Harpic'));
const HarpicAE = React.lazy(() => import('../../themes/HarpicAE'));
const DurexCA = React.lazy(() => import('../../themes/DurexCA'));
const KYCA = React.lazy(() => import('../../themes/KYCA'));
const LysolCA = React.lazy(() => import('../../themes/LysolCA'));
const VanishVictoriesAU = React.lazy(
  () => import('../../themes/VanishVictoriesAU')
);

const Layout = () => {
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');

  const componentMap = {
    vanish: <Vanish />,
    dettol: <Dettol />,
    finish: <Finish />,
    'finish-us': <FinishUS />,
    'durex-es': <DurexES />,
    'finish-uk': <FinishUK />,
    airwick: <Airwick />,
    'airwick-uk': <AirwickUK />,
    'airwick-uk-subscribe': <AirwickUKSubscribe />,
    harpic: <Harpic />,
    'harpic-ae': <HarpicAE />,
    'ky-ca': <KYCA />,
    'durex-ca': <DurexCA />,
    'lysol-ca': <LysolCA />,
    'vanish-victories-au': <VanishVictoriesAU />,
  };

  const componentToRender =
    brand && componentMap[brand] ? componentMap[brand] : <Default />;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      {componentToRender}
    </React.Suspense>
  );
};

export default Layout;
