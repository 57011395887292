import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout';
import Forms from './pages/Forms';
import NotFound from './pages/NotFound';
import Quizzes from './pages/Quizzes';
import { paths } from './paths';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path={paths.FORMS} element={<Layout />}>
        <Route path={paths.FORMS} element={<Forms />} />
      </Route>
      <Route path={paths.QUIZZES} element={<Layout />}>
        <Route path={paths.QUIZZES} element={<Quizzes />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default App;
